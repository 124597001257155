// MAP STYLES

// var styles = [
//     {
//         "featureType": "water",
//         "elementType": "all",
//         "stylers": [
//             {
//                 "visibility": "simplified"
//             },
//             {
//                 "hue": "#e9ebed"
//             },
//             {
//                 "saturation": -78
//             },
//             {
//                 "lightness": 67
//             }
//         ]
//     },
//     { 
//         "featureType": "landscape",
//         "elementType": "all",
//         "stylers": [
//             {
//                 "visibility": "simplified"
//             },
//             {
//                 "hue": "#ffffff"
//             },
//             {
//                 "saturation": -100
//             },
//             {
//                 "lightness": 100
//             }
//         ]
//     },
//     {
//         "featureType": "road",
//         "elementType": "geometry",
//         "stylers": [
//             {
//                 "visibility": "simplified"
//             },
//             {
//                 "hue": "#bbc0c4"
//             },
//             {
//                 "saturation": -93
//             },
//             {
//                 "lightness": 31
//             }
//         ]
//     },
//     {
//         "featureType": "poi",
//         "elementType": "all",
//         "stylers": [
//             {
//                 "visibility": "off"
//             },
//             {
//                 "hue": "#ffffff"
//             },
//             {
//                 "saturation": -100
//             },
//             {
//                 "lightness": 100
//             }
//         ]
//     },
//     {
//         "featureType": "road.local",
//         "elementType": "geometry",
//         "stylers": [
//             {
//                 "visibility": "simplified"
//             },
//             {
//                 "hue": "#e9ebed"
//             },
//             {
//                 "saturation": -90
//             },
//             {
//                 "lightness": -8
//             }
//         ]
//     },
//     {
//         "featureType": "transit",
//         "elementType": "all",
//         "stylers": [
//             {
//                 "visibility": "on"
//             },
//             {
//                 "hue": "#e9ebed"
//             },
//             {
//                 "saturation": 10
//             },
//             {
//                 "lightness": 69
//             }
//         ]
//     },
//     {
//         "featureType": "administrative.locality",
//         "elementType": "all",
//         "stylers": [
//             {
//                 "visibility": "on"
//             },
//             {
//                 "hue": "#2c2e33"
//             },
//             {
//                 "saturation": 7
//             },
//             {
//                 "lightness": 19
//             }
//         ]
//     },
//     {
//         "featureType": "road",
//         "elementType": "labels",
//         "stylers": [
//             {
//                 "visibility": "on"
//             },
//             {
//                 "hue": "#bbc0c4"
//             },
//             {
//                 "saturation": -93
//             },
//             {
//                 "lightness": 31
//             }
//         ]
//     },
//     {
//         "featureType": "road.arterial",
//         "elementType": "labels",
//         "stylers": [
//             {
//                 "visibility": "simplified"
//             },
//             {
//                 "hue": "#bbc0c4"
//             },
//             {
//                 "saturation": -93
//             },
//             {
//                 "lightness": -2
//             }
//         ]
//     }
// ];

var defaultDiacriticsRemovalMap = [
        {'base':'A', 'letters':'\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F'},
        {'base':'AA','letters':'\uA732'},
        {'base':'AE','letters':'\u00C6\u01FC\u01E2'},
        {'base':'AO','letters':'\uA734'},
        {'base':'AU','letters':'\uA736'},
        {'base':'AV','letters':'\uA738\uA73A'},
        {'base':'AY','letters':'\uA73C'},
        {'base':'B', 'letters':'\u0042\u24B7\uFF22\u1E02\u1E04\u1E06\u0243\u0182\u0181'},
        {'base':'C', 'letters':'\u0043\u24B8\uFF23\u0106\u0108\u010A\u010C\u00C7\u1E08\u0187\u023B\uA73E'},
        {'base':'D', 'letters':'\u0044\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018B\u018A\u0189\uA779\u00D0'},
        {'base':'DZ','letters':'\u01F1\u01C4'},
        {'base':'Dz','letters':'\u01F2\u01C5'},
        {'base':'E', 'letters':'\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E'},
        {'base':'F', 'letters':'\u0046\u24BB\uFF26\u1E1E\u0191\uA77B'},
        {'base':'G', 'letters':'\u0047\u24BC\uFF27\u01F4\u011C\u1E20\u011E\u0120\u01E6\u0122\u01E4\u0193\uA7A0\uA77D\uA77E'},
        {'base':'H', 'letters':'\u0048\u24BD\uFF28\u0124\u1E22\u1E26\u021E\u1E24\u1E28\u1E2A\u0126\u2C67\u2C75\uA78D'},
        {'base':'I', 'letters':'\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197'},
        {'base':'J', 'letters':'\u004A\u24BF\uFF2A\u0134\u0248'},
        {'base':'K', 'letters':'\u004B\u24C0\uFF2B\u1E30\u01E8\u1E32\u0136\u1E34\u0198\u2C69\uA740\uA742\uA744\uA7A2'},
        {'base':'L', 'letters':'\u004C\u24C1\uFF2C\u013F\u0139\u013D\u1E36\u1E38\u013B\u1E3C\u1E3A\u0141\u023D\u2C62\u2C60\uA748\uA746\uA780'},
        {'base':'LJ','letters':'\u01C7'},
        {'base':'Lj','letters':'\u01C8'},
        {'base':'M', 'letters':'\u004D\u24C2\uFF2D\u1E3E\u1E40\u1E42\u2C6E\u019C'},
        {'base':'N', 'letters':'\u004E\u24C3\uFF2E\u01F8\u0143\u00D1\u1E44\u0147\u1E46\u0145\u1E4A\u1E48\u0220\u019D\uA790\uA7A4'},
        {'base':'NJ','letters':'\u01CA'},
        {'base':'Nj','letters':'\u01CB'},
        {'base':'O', 'letters':'\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u00D6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C'},
        {'base':'OI','letters':'\u01A2'},
        {'base':'OO','letters':'\uA74E'},
        {'base':'OU','letters':'\u0222'},
        {'base':'OE','letters':'\u008C\u0152'},
        {'base':'oe','letters':'\u009C\u0153'},
        {'base':'P', 'letters':'\u0050\u24C5\uFF30\u1E54\u1E56\u01A4\u2C63\uA750\uA752\uA754'},
        {'base':'Q', 'letters':'\u0051\u24C6\uFF31\uA756\uA758\u024A'},
        {'base':'R', 'letters':'\u0052\u24C7\uFF32\u0154\u1E58\u0158\u0210\u0212\u1E5A\u1E5C\u0156\u1E5E\u024C\u2C64\uA75A\uA7A6\uA782'},
        {'base':'S', 'letters':'\u0053\u24C8\uFF33\u1E9E\u015A\u1E64\u015C\u1E60\u0160\u1E66\u1E62\u1E68\u0218\u015E\u2C7E\uA7A8\uA784'},
        {'base':'T', 'letters':'\u0054\u24C9\uFF34\u1E6A\u0164\u1E6C\u021A\u0162\u1E70\u1E6E\u0166\u01AC\u01AE\u023E\uA786'},
        {'base':'TZ','letters':'\uA728'},
        {'base':'U', 'letters':'\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u00DC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244'},
        {'base':'V', 'letters':'\u0056\u24CB\uFF36\u1E7C\u1E7E\u01B2\uA75E\u0245'},
        {'base':'VY','letters':'\uA760'},
        {'base':'W', 'letters':'\u0057\u24CC\uFF37\u1E80\u1E82\u0174\u1E86\u1E84\u1E88\u2C72'},
        {'base':'X', 'letters':'\u0058\u24CD\uFF38\u1E8A\u1E8C'},
        {'base':'Y', 'letters':'\u0059\u24CE\uFF39\u1EF2\u00DD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE'},
        {'base':'Z', 'letters':'\u005A\u24CF\uFF3A\u0179\u1E90\u017B\u017D\u1E92\u1E94\u01B5\u0224\u2C7F\u2C6B\uA762'},
        {'base':'a', 'letters':'\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250'},
        {'base':'aa','letters':'\uA733'},
        {'base':'ae','letters':'\u00E6\u01FD\u01E3'},
        {'base':'ao','letters':'\uA735'},
        {'base':'au','letters':'\uA737'},
        {'base':'av','letters':'\uA739\uA73B'},
        {'base':'ay','letters':'\uA73D'},
        {'base':'b', 'letters':'\u0062\u24D1\uFF42\u1E03\u1E05\u1E07\u0180\u0183\u0253'},
        {'base':'c', 'letters':'\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184'},
        {'base':'d', 'letters':'\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A'},
        {'base':'dz','letters':'\u01F3\u01C6'},
        {'base':'e', 'letters':'\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD'},
        {'base':'f', 'letters':'\u0066\u24D5\uFF46\u1E1F\u0192\uA77C'},
        {'base':'g', 'letters':'\u0067\u24D6\uFF47\u01F5\u011D\u1E21\u011F\u0121\u01E7\u0123\u01E5\u0260\uA7A1\u1D79\uA77F'},
        {'base':'h', 'letters':'\u0068\u24D7\uFF48\u0125\u1E23\u1E27\u021F\u1E25\u1E29\u1E2B\u1E96\u0127\u2C68\u2C76\u0265'},
        {'base':'hv','letters':'\u0195'},
        {'base':'i', 'letters':'\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131'},
        {'base':'j', 'letters':'\u006A\u24D9\uFF4A\u0135\u01F0\u0249'},
        {'base':'k', 'letters':'\u006B\u24DA\uFF4B\u1E31\u01E9\u1E33\u0137\u1E35\u0199\u2C6A\uA741\uA743\uA745\uA7A3'},
        {'base':'l', 'letters':'\u006C\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747'},
        {'base':'lj','letters':'\u01C9'},
        {'base':'m', 'letters':'\u006D\u24DC\uFF4D\u1E3F\u1E41\u1E43\u0271\u026F'},
        {'base':'n', 'letters':'\u006E\u24DD\uFF4E\u01F9\u0144\u00F1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5'},
        {'base':'nj','letters':'\u01CC'},
        {'base':'o', 'letters':'\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275'},
        {'base':'oi','letters':'\u01A3'},
        {'base':'ou','letters':'\u0223'},
        {'base':'oo','letters':'\uA74F'},
        {'base':'p','letters':'\u0070\u24DF\uFF50\u1E55\u1E57\u01A5\u1D7D\uA751\uA753\uA755'},
        {'base':'q','letters':'\u0071\u24E0\uFF51\u024B\uA757\uA759'},
        {'base':'r','letters':'\u0072\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783'},
        {'base':'s','letters':'\u0073\u24E2\uFF53\u00DF\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B'},
        {'base':'t','letters':'\u0074\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787'},
        {'base':'tz','letters':'\uA729'},
        {'base':'u','letters': '\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289'},
        {'base':'v','letters':'\u0076\u24E5\uFF56\u1E7D\u1E7F\u028B\uA75F\u028C'},
        {'base':'vy','letters':'\uA761'},
        {'base':'w','letters':'\u0077\u24E6\uFF57\u1E81\u1E83\u0175\u1E87\u1E85\u1E98\u1E89\u2C73'},
        {'base':'x','letters':'\u0078\u24E7\uFF58\u1E8B\u1E8D'},
        {'base':'y','letters':'\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF'},
        {'base':'z','letters':'\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763'}
    ];

var diacriticsMap = {};
for (var i=0; i < defaultDiacriticsRemovalMap .length; i++){
    var letters = defaultDiacriticsRemovalMap [i].letters;
    for (var j=0; j < letters.length ; j++){
        diacriticsMap[letters[j]] = defaultDiacriticsRemovalMap [i].base;
    }
}

function removeDiacritics (str) {
    return str.replace(/[^\u0000-\u007E]/g, function(a){ 
       return diacriticsMap[a] || a; 
    });
}

var dezooms = {
	'FR' : -2,
	'US' : -2,
	'DE' : -2,
	'UK' : -2,
	'ES' : -2,
	'IT' : -2
};

// HANDLEBARS HELPERS

var slugify = function(component) {
	component = removeDiacritics(component);
	var slug = component.replace(/[^\w\s]+/gi, '').replace(/ +/gi, '-');
	return slug.toLowerCase();
};

Handlebars.registerHelper('slugify', function (component, options) {
	return slugify(component);
});

var markers = {
	'dealer' 	: 'new_dealers',
	'studio' 	: 'new_studio',
	'gallery' 	: 'light_grey-g',
	'store' 	: 'new_house',
	'showroom' : 'light_grey-showroom',
	'importer' : 'importer'
};

var infosClasses = [];

// CORE

var clearSummary = function(){
	$('.search-selection').html('');
	$('button.uncheck').get(0).click();
};

var loadSearch = function(){

	var datas 	= {};
	var labels 	= {};
	
	labels.country = $($('select[name=country]').find(":selected").get(0)).text();
	datas.country = $('select[name=country]').val();
	
	labels.postal = datas.postal = $('input[name=postal]').val();
	
	var energies = $('.energies input[value]:checked, input[type=hidden][name*=energies]');

	if( energies.length > 0 ) {
		
		datas.energies = [];
		labels.energies = [];
		
		energies.each(function(){
			
			datas.energies.push($(this).val());
			
			var id = $(this).attr('id');
			labels.energies.push( $('label[for=' + id + ']' ).text() );
			
		});
	}
	
	if( $('input.choose-model').prop('checked') ) {
		var models = $('.product-single:not(.hidden) input[type=checkbox]:checked');
		
		datas.models = [];
		labels.models = [];
		
		models.each( function(){
			datas.models.push( $(this).val());
			labels.models.push( $(this).data('title'));
		});
	
	}
	
	var source   = $("#search-selection-template").html();
	if(source) {
		var template = Handlebars.compile(source); 
		var html = template(labels);
		$('.search-selection').html(html);
		$('#posmaptwo').addClass('hidden');
	}
	
	return datas;	
};

var toggleProductModal = function(open) {
	if(open) {
		
		$('body').addClass('noscroll');
		
		var energies = $('.energies input[value]:checked, input[type=hidden][name*=energies]');
		if( energies.length > 0 ) {
			
			$('.product-single').addClass('hidden');
			energies.each(function(){
				var id = $(this).attr('id');
				
				$('.product-single.' + id).removeClass('hidden');
			});
			
		} else {
			$('.product-single').removeClass('hidden');
		}
		
		adaptPOSSelector();
		$('.pos-product-selector').addClass('active');
		
		if( $('.pos-products').length === 0 ){
			$('body, html').animate({scrollTop: 0 });
		}
		
	} else {
		
		$('body').removeClass('noscroll');
		
		if($('.product-single:not(.hidden) input[type=checkbox]:checked').length <= 0){
			$('input.all-model').prop('checked', true);
			$('input.choose-model').prop('checked', false);
		}
		
		$('.pos-product-selector').removeClass('active');
	}
};

var setMarkersImages = function(option) {
	
	if( option.data('redirection').length > 0 ) {
		
		var href = option.data('redirection');
		
		$('.redirection.modal').fadeIn();
				
		$('.redirection.modal').find('span.url').text(href);
		$('.redirection.modal').find('a.url').attr('href', href);
		
		$('.redirection.modal').find('.close').one( 'click', function(){
			$('.redirection.modal').fadeOut();	
		});
		
		return false;
	}
	
	markers = {
		'dealer' 	: $(option).data('dealer-marker'),
		'studio' 	: $(option).data('studio-marker'),
		'gallery' 	: $(option).data('gallery-marker'),
		'store' 	: $(option).data('store-marker'),
		'showroom'  : $(option).data('showroom-marker'),
		'importer'  : 'importer'
	};
	
};

var hideModelsBox = function(hide){
	if(hide) {
		$('.selector-block.models').hide();
		// $('.selector-block').addClass('large');
	} else {
		$('.selector-block.models').show();
		// $('.selector-block').removeClass('large');
	}
};

function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
            return decodeURIComponent(pair[1]);
        }
    }
}

$(document).ready( function(){
	
	if( $('.form-appointment').length > 0 ) {
		
		$('a[href*=\\#]').on('click', function(event){     
		    event.preventDefault();
		    $('html,body').animate({scrollTop:$(this.hash).offset().top}, 500);
		    return false;
		});
		
	}
	
	$('.appointment-thanks .close').click( function(){
		$('.appointment-thanks').fadeOut(function(){
			$(this).remove();
		});
	});
	
	if( $('.map, .pos-products').length > 0 ) {
		
		$('select[name=country]').change( function(){
			setMarkersImages( $(this).find(":selected") );
			hideModelsBox( $(this).find(":selected").data('show-models'));
			
			clearSummary();			
		});
		
		setMarkersImages( $('select[name=country]').find(":selected") );
		hideModelsBox( $('select[name=country]').find(":selected").data('show-models'));	
		
		$('.map').on( 'click', '.infowindow a.see-more', function(evt){
			evt.preventDefault();
			
			if (typeof ga !== "undefined") {
				try { 
					ga('send', {
					  hitType: 'event',
					  eventCategory: 'Dealer',
					  eventAction: 'Click',
					  eventLabel: $(this).data('client')
					});
				} catch(e) {}
			}
			
			var href = $(this).attr('href');
			setTimeout(function(){
				window.location.href = href;
			}, 1000);
			
			return false;
		});
		
		$('input.energy-type').change(function(){
			
			if($(this).prop('checked')) {
				$('input.choose-model').prop('checked', false);
				$('input.all-model').prop('checked', true);
			}
			
			// convert to radio button
			$('input.energy-type').not(this).prop('checked', false);
			$(this).prop('checked', true);
			
			$('input.all-type').prop('checked', false);
		});
		
		$('input.all-type').change(function(){ $('input.energy-type').prop('checked', false); });
		
		$('input.choose-model').change(function(){ $('input.all-model').prop('checked', false); });
		$('input.all-model').change(function(){ $('input.choose-model').prop('checked', false); });
		
		$('input.choose-model').change( function(){
			
			// Force selection
			$(this).prop("checked", true);
			
			if( $(this).prop("checked") ) {
				toggleProductModal(true);
				$(this).prop("checked", true);
				
			} else {
				$('input.all-model').prop('checked', true);
			}
		});
		
		$('.pos-product-selector .close').click(function(){
			toggleProductModal(false);
		});
		
		
		$('.pos-product-selector .validate').click(function(){
			toggleProductModal(false);
			loadSearch();
			return false;
		});
		
		$('input[type=checkbox].reload').change(loadSearch);
		$('input[type=text].reload').keyup(loadSearch);
		$('select.reload').change(loadSearch);
		
		$('#filter-search .required').focus( function(){
			$(this).removeClass('error-warning');
		});
		
		$('.do-search').click(function(){
		
			var error = false;
			$('#filter-search .required').each(function(){
				if( $(this).val().length <= 0 ) {
					$(this).addClass('error-warning');
					error = true;
				}	
			});
			
			if(!error) {
				
				var datas = loadSearch();
			
				if(datas.postal.length > 0){
					Cookies.set('_pos', JSON.stringify(datas) );
				}
				
				if( $('.pos-products').length > 0 ) {
					
					return true;
										
				} else {
					doSearch(datas);
				}
			}
		
			return false;
			
		});
		
		loadSearch();
		
		$("input[name=postal]").keypress(function(event) {
			
			    if (event.which == 13) {
			        event.preventDefault();
			        
			        if($(this).val().length > 0) {
			        
				        $('.do-search').trigger('click');
				        
				        if($(this).hasClass('in-pos')) {
					        var href = $('.do-search').attr('href');
					        window.open(href, "_blank");
				        }
				     } else {
					   $(this).addClass('error-warning');
		    		}
			    }
		});
				
	}

	if( window.location.href.indexOf('?type') > -1 ) {
		var energy = getQueryVariable('type');
		$('.energies input').prop('checked', false);
		$('.energies input[id=' + energy + ']').prop('checked', true);	
	}
		
});

var adaptPOSSelector = function(){
	$('.pos-product-selector:not(.scrollable)').css('min-height', ($('body').height() + 30) + 'px');	
};

$(window).resize(adaptPOSSelector);
adaptPOSSelector();

/// MAP

var map;
var mapDefault;
var informations = {};
var infowindow;

var clearMarkers = function(){
	for(var i in informations){
		informations[i].marker.setMap(null);
	}
	
	infosClasses = [];
	$('.legend').hide();
};

var getMarker = function(type, size){	
	
	var image = THEME_PATH + 'img/map/markers/' + markers[type] + '.png';
	
	$('.infos-sup').show();
	$('.legend.' + type).show();
	$('.legend.' + type + ' span.desc').addClass(markers[type]);
	
	return {
			url: image, // url
	    	scaledSize: new google.maps.Size(size, size), // scaled size
	    	origin: new google.maps.Point(0,0), // origin
	    	anchor: new google.maps.Point(Math.round(size/2), size) // anchor
		};
};
function openParentP(el){

	$(el).parents('div').find('p').show();
}
var openWindow = function(marker, map, dealer) {
	
	if(!dealer.STUVCORNER) dealer.STUVCORNER = 'dealer'; 
	
	var html = '<div class="infowindow" >';
	
	var locale = undefined !== SITE_LOCALE && SITE_LOCALE.length > 0 ? SITE_LOCALE : $('html').attr('lang');
	
	var url = parseInt(dealer.DEALERID) > 0 ? "/" + locale +"/dealer-"+ dealerNid +"-" + dealer.DEALERID + "-" + slugify(dealer.COMPANY) + "-" + slugify(dealer.CITY) : false;
	if( undefined !== dealer.URL && dealer.URL ) url = dealer.URL.replace('[language]', locale);
	if(url) url += '?p=' + $('input[name=postal]').val() + '&t=' + $('.energies input[value]:checked').val();
	
	/*if(dealer.hasOwnProperty('ACTION') && dealer.ACTION !== false) {
		html += "<a href=\"https://stuvdays.stuv.com/" + locale + "\" target=\"_blank\"  >";
		html += '<img class="stuvday-img" src="/sites/all/themes/shared/dist/img/StuvDays.svg" style="margin:20px 20px 10px 20px; padding-bottom:20px; border-bottom: 1px solid #C9C9C9" alt="">';
		html += "</a>"; 
	}*/
	
	var knowMoreLabel = $('script[data-t-more-info]').data('t-more-info');
	if(!knowMoreLabel || knowMoreLabel.length <= 0) knowMoreLabel = "En savoir plus";

	if(SITE_LOCALE == 'fr-fr' && dealer.hasOwnProperty('ACTION') && dealer.ACTION !== false) {
		html += "<a href=\"https://printempsdelarchitecture.stuv.com/fr-fr/ target=\"_blank\"  >";
		html += '<img width="270" class="stuvday-img" src="/sites/all/themes/shared/dist/img/StuvDays2021.svg" style="width:270px;margin:20px 20px 10px 20px; padding-bottom:20px; border-bottom: 1px solid #C9C9C9" alt="">';
		html += "</a>"; 
	}
	html += "<span>";
	html += dealer.CITY ;
	html += "</span>";
	html += "<h4>";
	if(url) html += "<a href=\"" + url + "\" >";
	html += dealer.COMPANY;
	if(url) html += "</a>";
	html += "</h4>"; 
	html += "<a href=\"" + url + "\" class=\"more--info_windows\" data-client=\"" + dealer.DEALERID + "\" >" + knowMoreLabel;
	html += "</a>"; 
	
	html += "<p>";
	html += dealer.STREET + "<br>" + dealer.POSTALCODE + " " + dealer.CITY + "<br><br>";
	
	if(dealer.STUVCORNER.toLowerCase().indexOf("showroom") > -1 && translations.hasOwnProperty('showroom_append_1') ) html += translations.showroom_append_1 + '<br><br>'; 
	
	if( undefined !== dealer.EMAIL && dealer.EMAIL ) html += '<a href="mailto:' + dealer.EMAIL + '" >' + dealer.EMAIL + '</a><br>';
	if( undefined !== dealer.WEB && dealer.WEB && dealer.WEB.trim().length > 0 ) html += '<a href="' + ( dealer.WEB.indexOf('http') < 0 ? 'http://' + dealer.WEB : dealer.WEB ) + '" target="_blank">' + dealer.WEB + '</a><br>';
	if( undefined !== dealer.PHONE && dealer.PHONE ) html += '<br>' + dealer.PHONE;
	
	if( url ) {
		html += "<a href=\"" + url + "\" class=\"see-more\" data-client=\"" + dealer.DEALERID + "\" >";
		html += $('.map').data('more-label');
		html += "</a>"; 
	}
	
	if( parseInt(dealer.DEALERID) > 0 ) {
		html += "<a href=\"" + url + "#appointment\" class=\"see-more\" data-client=\"" + dealer.DEALERID + "\" >";
		html += $('.map').data('appointment');
		html += "</a>"; 
	}
	
	html += "</p>";
	
	html += "</div>";
	
	infowindow.setContent(html);
    infowindow.open(map, marker);
    
    if (typeof ga !== "undefined") {  
	    ga('send', {
		  hitType: 'event',
		  eventCategory: 'Dealer',
		  eventAction: 'Highlighted',
		  eventLabel: dealer.DEALERID
		});
	}

};

var refreshMap = function(country, dealers, zoomOnFirst) {
	
	if(undefined === zoomOnFirst) zoomOnFirst = false;
	
	clearMarkers();
	
	var geocoder = new google.maps.Geocoder();
	infowindow = new google.maps.InfoWindow();
	
	var addMarkerEvent = function(marker, map, dealer){
		
		marker.addListener('click', function() {
			openWindow(marker, map, dealer);
		});
	};
	
	var countryName = '';
	switch(country) {
		// case 'UK':
		// 	countryName = 'GB';
		// 	break;
		default:
			countryName = country;
	}
	
	var countryName = $('select[name=country] option[value=' + countryName + ']').text();	
	geocoder.geocode({'address': countryName}, function(results, status){
		
		if (status == google.maps.GeocoderStatus.OK) {

			map = new google.maps.Map(document.getElementById('posmap'), {
			  center: results[0].geometry.location,
			  scrollwheel: false,
			  zoom: 8,
			  //styles: styles
			  //center: { lat: 50.3931007, lng: 4.8208966 },
			  styles: [
				{
					"elementType": "geometry",
					"stylers": [
					  {
						"color": "#f5f5f5"
					  }
					]
				  },
				  {
					"elementType": "labels.icon",
					"stylers": [
					  {
						"visibility": "off"
					  }
					]
				  },
				  {
					"elementType": "labels.text.fill",
					"stylers": [
					  {
						"color": "#616161"
					  }
					]
				  },
				  {
					"elementType": "labels.text.stroke",
					"stylers": [
					  {
						"color": "#f5f5f5"
					  }
					]
				  },
				  {
					"featureType": "administrative.land_parcel",
					"elementType": "labels.text.fill",
					"stylers": [
					  {
						"color": "#bdbdbd"
					  }
					]
				  },
				  {
					"featureType": "poi",
					"elementType": "geometry",
					"stylers": [
					  {
						"color": "#eeeeee"
					  }
					]
				  },
				  {
					"featureType": "poi",
					"elementType": "labels.text.fill",
					"stylers": [
					  {
						"color": "#757575"
					  }
					]
				  },
				  {
					"featureType": "poi.park",
					"elementType": "geometry",
					"stylers": [
					  {
						"color": "#e5e5e5"
					  }
					]
				  },
				  {
					"featureType": "poi.park",
					"elementType": "labels.text.fill",
					"stylers": [
					  {
						"color": "#9e9e9e"
					  }
					]
				  },
				  {
					"featureType": "road",
					"elementType": "geometry",
					"stylers": [
					  {
						"color": "#ffffff"
					  }
					]
				  },
				  {
					"featureType": "road.arterial",
					"elementType": "labels.text.fill",
					"stylers": [
					  {
						"color": "#757575"
					  }
					]
				  },
				  {
					"featureType": "road.highway",
					"elementType": "geometry",
					"stylers": [
					  {
						"color": "#dadada"
					  }
					]
				  },
				  {
					"featureType": "road.highway",
					"elementType": "labels.text.fill",
					"stylers": [
					  {
						"color": "#616161"
					  }
					]
				  },
				  {
					"featureType": "road.local",
					"elementType": "labels.text.fill",
					"stylers": [
					  {
						"color": "#9e9e9e"
					  }
					]
				  },
				  {
					"featureType": "transit.line",
					"elementType": "geometry",
					"stylers": [
					  {
						"color": "#e5e5e5"
					  }
					]
				  },
				  {
					"featureType": "transit.station",
					"elementType": "geometry",
					"stylers": [
					  {
						"color": "#eeeeee"
					  }
					]
				  },
				  {
					"featureType": "water",
					"elementType": "geometry",
					"stylers": [
					  {
						"color": "#c9c9c9"
					  }
					]
				  },
				  {
					"featureType": "water",
					"elementType": "geometry.fill",
					"stylers": [
					  {
						"color": "#cfdce2"
					  },
					  {
						"visibility": "on"
					  }
					]
				  },
				  {
					"featureType": "water",
					"elementType": "labels.text.fill",
					"stylers": [
					  {
						"color": "#9e9e9e"
					  }
					]
				  }
			]
			});
			
			//if (results[0].geometry.viewport && !zoomOnFirst) {
				
				google.maps.event.addListener(map, 'zoom_changed', function() {
				    var zoomChangeBoundsListener = google.maps.event.addListener(map, 'bounds_changed', function(event) {
				        if (this.initialZoom === true) {
					        
					        var dezoom = 0;
					        if( dezooms.hasOwnProperty(country) && dezoom.country ) dezoom = dezooms[country];
					        
							this.setZoom(this.getZoom() + dezoom); 
							this.initialZoom = false;							
				        }
				        google.maps.event.removeListener(zoomChangeBoundsListener);
				    });
				});
				
				map.initialZoom = true;
				//map.fitBounds(results[0].geometry.viewport);
			
			//} 
			
			if(undefined !== dealers) {
				
				var bounds = new google.maps.LatLngBounds();
				
				for(var i in dealers){
					
					var dealer = dealers[i];
					var icon;
					
					if(!dealer.STUVCORNER) dealer.STUVCORNER = 'dealer';
					
					try {
					
						switch(true){
							case (dealer.STUVCORNER.toLowerCase().indexOf("store") > -1 ):
							case (dealer.STUVCORNER.toLowerCase().indexOf("house") > -1 ):
								icon = getMarker('store', 45);
								break;
							case (dealer.STUVCORNER.toLowerCase().indexOf("gallery") > -1 ):
								icon = getMarker('gallery', 45);
								break;
							case (dealer.STUVCORNER.toLowerCase().indexOf("studio") > -1 ):
								icon = getMarker('studio', 45);
								break;
							case (dealer.STUVCORNER.toLowerCase().indexOf("showroom") > -1 ):
								icon = getMarker('showroom', 45);
								break;
							default:
								// tester si importateur = y > getmarker = new marker
								icon = dealer.IMPORTATEUR ? getMarker('importer', 45) : getMarker('dealer', 45);
						}
					
					} catch(e) {
						// tester si importateur = y > getmarker = new marker
						icon = dealer.IMPORTATEUR ? getMarker('importer', 45) : getMarker('dealer', 45);
					}					
					

					var lat = parseFloat(dealer.GOOGLELAT);
					var lng = parseFloat(dealer.GOOGLELNG);
					
					if( !isNaN(lat) && !isNaN(lng) ) {
						
						var marker = new google.maps.Marker({
						    position: {  lat:lat  , lng:lng },
						    map: map,
						    title: dealer.COMPANY,
						    icon: icon,
						    optimized: false
						});	
						
						try {
							if( dealer.STUVCORNER.toLowerCase().indexOf("showroom") < 0 ) {
								bounds.extend(marker.position);	
							}
						} catch(e) {
							bounds.extend(marker.position);	
						}
	
						addMarkerEvent(marker, map, dealer);
						
						informations['dealer_' + dealer.DEALERID ] = {
							marker:marker,
							dealer:dealer
						};
					
					}										
				}
				
				//if(zoomOnFirst) {
				if(true) {
					if(bounds){
						map.fitBounds(bounds);
						map.setCenter( bounds.getCenter() );
						//map.setZoom(map.getZoom() -2);
					}
				}
			}
	    } 
	});	
};

var refreshList = function(res, scroll) {
	
	$('.bloc_info--dealers .store').addClass('disabled');
	
	$('.content--studio--showroom .studio').addClass('disabled');
	$('.content--studio--showroom .importer').addClass('disabled');
	$('.content--studio--showroom .showroom').addClass('disabled');
	$('.content--studio--showroom .reseller').addClass('disabled');
	
	for(var i in res) {
		var dealer = res[i];
		
		if(!dealer.STUVCORNER) dealer.STUVCORNER = 'dealer';
		
		if( dealer.hasOwnProperty('WEB') && dealer.WEB && dealer.WEB.indexOf('http') === -1 ) {
			res[i].WEB = dealer.WEB.trim().length > 0 ? 'http://' + dealer.WEB : '';
		}
		
		if( dealer.hasOwnProperty('IMPORTATEUR') && dealer.IMPORTATEUR ) {
			res[i].IMPORTATEUR = dealer.IMPORTATEUR == 'Y' ? true : false;
		}
		
		if( dealer.hasOwnProperty('ISINDEPARTMENT') && dealer.ISINDEPARTMENT ) {
			res[i].NOTINDEPARTMENT = dealer.ISINDEPARTMENT == 'N' ? true : false;
		}

		res[i].IS_NOT_STORE = true;

		try {
		
			switch(true){
				case (dealer.STUVCORNER.toLowerCase().indexOf("store") > -1 ):
				case (dealer.STUVCORNER.toLowerCase().indexOf("house") > -1 ):
					res[i].TYPE = 'store';
					res[i].IS_NOT_STORE = false;
					$('.bloc_info--dealers .store').removeClass('disabled');
					break;
				case (dealer.STUVCORNER.toLowerCase().indexOf("gallery") > -1 ):
					res[i].TYPE = 'gallery';
					break;
				case (dealer.STUVCORNER.toLowerCase().indexOf("studio") > -1 ):
					res[i].TYPE = 'studio';
					$('.content--studio--showroom .studio').removeClass('disabled');
					break;
				case (dealer.STUVCORNER.toLowerCase().indexOf("showroom") > -1 ):
					res[i].TYPE = 'showroom';
					$('.content--studio--showroom .showroom').removeClass('disabled');
					break;
				default:
					if (res[i].IMPORTATEUR) {
						res[i].TYPE = 'importer';
						$('.content--studio--showroom .importer').removeClass('disabled');
					} else {
						res[i].TYPE = 'dealer';
						$('.content--studio--showroom .reseller').removeClass('disabled');
					}
			}
		
		} catch(e) {
			if (res[i].IMPORTATEUR) {
				res[i].TYPE = 'importer';
			} else {
				res[i].TYPE = 'dealer';
			}
		}
		
		res[i].MARKER_TYPE = markers[res[i].TYPE];
		
		if (typeof ga !== "undefined") {
			ga('send', {
			  hitType: 'event',
			  eventCategory: 'Dealer',
			  eventAction: 'Impression',
			  eventLabel: dealer.DEALERID
			});
		}
	}
	
	// Set stores on top
	var stores = res.map(function(item){
		if(item.TYPE == 'store') return item;
		return false;
	});
	var others = res.map(function(item){
		if(item.TYPE !== 'store') return item;
		return false;
	});
	
	res = stores.concat(others).filter(Boolean);
		
	//var source   = $("#dealers-template").html();
	var source   = $("#new-dealers-template").html();
	
	if(source){
		var template = Handlebars.compile(source); 
		var html = template(res);
	}
	
	//$('.dealers-list').html(html);
	$('.list_dealers_list').html(html);
	
	//$('.dealers-list').find('a[data-map-window]').click( function(evt){
	$('.list_dealers_list').find('a[data-map-window]').click( function(evt){
		
		if( $(window).width() <= 700 ) return true;
		
		var client = $(this).data('map-window');
		var info = informations['dealer_' + client ];
		
		openWindow(info.marker, map, info.dealer);
		map.panTo(info.marker.getPosition());
		
		evt.preventDefault();
		return false;
	});

	if(scroll) {
		$('body, html').animate({scrollTop: $('.map').offset().top - 250 });
	}
}; 

var loadDatas = function(datas, scroll, zoomOnFirst){
	
	$('.query-results').hide();
	$('.query-results .empty').hide();
	
	var loader = $('<div class="loader"><img src="' + THEME_PATH + 'img/map/rolling.gif" /></div>');
	$('body').append(loader);
	
	// Hack de merde
	if( datas.hasOwnProperty('QP10') && datas.QP10 && datas.QP10 == 'on' ) {
		delete datas.QP10;
		datas.tabactif = 'Pellets';
	}
		
	$.get('/api/stuvweb/search?' + $.param(datas) + '&' + Math.round(Math.random() * 999999) , function(results){
		
		var firstResultHasShowroom = false;
		try {
			firstResultHasShowroom = results[0].STUVCORNER.indexOf('showroom') > -1;
		} catch(e) {}
			
		if( results.length === 0 || ( results.length === 1 && firstResultHasShowroom )) {
			$('.map').hide();
			$('.content-dealers').hide();
			$('div.loader').hide();
			$('.query-results').show();
			$('.query-results .empty').show();

			return;
		}

		$('.map').show();
		$('.content-dealers').show();
		$('.query-results').hide();
		$('.query-results .empty').hide();
				
		var country;
		
		if( datas.hasOwnProperty('Pays') && datas.Pays) {
			country = datas.Pays;
		} else {
			country = $('.search select[name=country]').val();
		}
		
		$('.query-results .total').html( results.length );
		$('.query-results').fadeIn();
		
		refreshMap(country, results, zoomOnFirst);
		refreshList(results, scroll);
		
		loader.fadeOut(function(){
			$(this).remove();
		});

	}, 'json');
};


// SEARCH

var doSearch = function(datas) {
	
	var params = {
		'Pays' : datas.country
	};
	
	if( datas.hasOwnProperty('postal') && datas.postal && datas.postal.length > 0 ) {
		params.codePostal = datas.postal;
	}

	if( datas.hasOwnProperty('energies') && datas.energies && datas.energies.length > 0 ) {
		params.tabactif = datas.energies.join(',');
	}
	
	if( datas.hasOwnProperty('models') && datas.models && datas.models.length > 0 ) {
		for(var i in datas.models){
			var model = datas.models[i].replace( /[^a-z0-9]+/i , '');
			params[model] = 'on';
		}
	}
	
	loadDatas(params, true, true);		
	$('.map').slideDown();
	
};


// INIT
function initMap() {
	try {
		refreshMap(country_code, dealers);
		
	} catch(e) {
		
	}
	
	if( window.location.href.indexOf('?reload') > -1 ) {
		
		var json = Cookies.get('_pos');
				
		if(json) {
			var datas = JSON.parse(json);
			
			$('.energies input').prop('checked', false);
			if( datas.hasOwnProperty('energies') && datas.energies && datas.energies.length >= 1 ) {
				for(var i in datas.energies) {
					var energy = datas.energies[i];
					$('.energies input[value=' + energy + ']').prop('checked', true);
				}
			}
			
			if( datas.hasOwnProperty('postal') && datas.postal) {
				$('input[name=postal]').val(datas.postal);
			}
			
			if( datas.hasOwnProperty('country') && datas.country) {
				$('select[name=country]').val(datas.country);
				$('select[name=country]').trigger('change');
			}
			
			if( datas.hasOwnProperty('models') && datas.models && datas.models.length >= 1 ) {				
				
				datas.models = datas.models.filter(function(x){
					return (x !== (undefined || null || ''));
				});
				
				for(var o in datas.models) {
					var model = datas.models[o];
					var input = $('.product-single input[value=' + model + ']');
					input.prop('checked', true);
				}
				
				$('input.choose-model').prop('checked', true);				
			}
			
			doSearch(datas);
			$('.map').slideDown();
		}
	}

	
} 


// SIZE

var maxHeight = 0;

$("div.dealers-details div.details-txt").each(function(){
   if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
});

$("div.dealers-details div.details-txt").height(maxHeight);
setTimeout(function () {  
	$('.more--info_windows').click( function() {

		$('.infowindow p').fadeIn();
		
	  });
}, 1000);

var mapDefault;

function mapDefault() {
	mapDefault = new google.maps.Map(document.getElementById("posmaptwo"), {
	  center: { lat: 50.3931007, lng: 4.8208966 },
	  zoom: 8,
	  styles: [
		{
			"elementType": "geometry",
			"stylers": [
			  {
				"color": "#f5f5f5"
			  }
			]
		  },
		  {
			"elementType": "labels.icon",
			"stylers": [
			  {
				"visibility": "off"
			  }
			]
		  },
		  {
			"elementType": "labels.text.fill",
			"stylers": [
			  {
				"color": "#616161"
			  }
			]
		  },
		  {
			"elementType": "labels.text.stroke",
			"stylers": [
			  {
				"color": "#f5f5f5"
			  }
			]
		  },
		  {
			"featureType": "administrative.land_parcel",
			"elementType": "labels.text.fill",
			"stylers": [
			  {
				"color": "#bdbdbd"
			  }
			]
		  },
		  {
			"featureType": "poi",
			"elementType": "geometry",
			"stylers": [
			  {
				"color": "#eeeeee"
			  }
			]
		  },
		  {
			"featureType": "poi",
			"elementType": "labels.text.fill",
			"stylers": [
			  {
				"color": "#757575"
			  }
			]
		  },
		  {
			"featureType": "poi.park",
			"elementType": "geometry",
			"stylers": [
			  {
				"color": "#e5e5e5"
			  }
			]
		  },
		  {
			"featureType": "poi.park",
			"elementType": "labels.text.fill",
			"stylers": [
			  {
				"color": "#9e9e9e"
			  }
			]
		  },
		  {
			"featureType": "road",
			"elementType": "geometry",
			"stylers": [
			  {
				"color": "#ffffff"
			  }
			]
		  },
		  {
			"featureType": "road.arterial",
			"elementType": "labels.text.fill",
			"stylers": [
			  {
				"color": "#757575"
			  }
			]
		  },
		  {
			"featureType": "road.highway",
			"elementType": "geometry",
			"stylers": [
			  {
				"color": "#dadada"
			  }
			]
		  },
		  {
			"featureType": "road.highway",
			"elementType": "labels.text.fill",
			"stylers": [
			  {
				"color": "#616161"
			  }
			]
		  },
		  {
			"featureType": "road.local",
			"elementType": "labels.text.fill",
			"stylers": [
			  {
				"color": "#9e9e9e"
			  }
			]
		  },
		  {
			"featureType": "transit.line",
			"elementType": "geometry",
			"stylers": [
			  {
				"color": "#e5e5e5"
			  }
			]
		  },
		  {
			"featureType": "transit.station",
			"elementType": "geometry",
			"stylers": [
			  {
				"color": "#eeeeee"
			  }
			]
		  },
		  {
			"featureType": "water",
			"elementType": "geometry",
			"stylers": [
			  {
				"color": "#c9c9c9"
			  }
			]
		  },
		  {
			"featureType": "water",
			"elementType": "geometry.fill",
			"stylers": [
			  {
				"color": "#cfdce2"
			  },
			  {
				"visibility": "on"
			  }
			]
		  },
		  {
			"featureType": "water",
			"elementType": "labels.text.fill",
			"stylers": [
			  {
				"color": "#9e9e9e"
			  }
			]
		  }
	]
	});
  }












