var v = document.getElementById('v');
var aList = document.querySelectorAll('.link-list a');
var tList = document.querySelectorAll('.text-list .step-label');

var a, t;
var doneOnce = false,
	stopper, texter;
	
var events = [];

var current = 0;

var obj = {
	begin: 0,
	end: 0,
	index: [],
	playing: [],
	aDom: [],
	tDom: [],
	lapBegin: [],
	textTiming: [],
	duration: []
}

for (var i = 0; i < aList.length; i++) {
	a = aList[i];
	t = tList[i];

	obj.index[i] = i;
	obj.playing[i] = false;
	obj.aDom[i] = a;
	obj.tDom[i] = t;
	obj.lapBegin[i] = parseFloat(a.dataset.begin);
	obj.textTiming[i] = parseFloat(t.dataset.timing);

	if (aList[i+1]) {
		obj.duration[i] = parseFloat(aList[i + 1].dataset.begin - a.dataset.begin);
	} else {
		obj.duration[i] = 0;
	}
}

if(v) {
    v.src = v.dataset.src;
    
    v.addEventListener('canplay', function () {
    	// Canplay devrait détecter qu'il y a au moins 10 secondes (par exemple) de chargé
    	if (!doneOnce) {
        	
        	events.push('canplay');
        	if(events.length == 2) startSlider();
    	}
    });
}

function startSlider() {

	buffered();
	
	obj.end = v.duration;
	
	var lastIndex = obj.duration.length - 1;
	obj.duration[lastIndex] = parseFloat(v.duration - obj.lapBegin[lastIndex]);
}

function stopVideo (at) {
	stopper = setTimeout(function () {
		v.pause();
	}, at * 1000);
}

function showText (at, el) {
	texter = setTimeout(function () {
		el.classList.add('active');
	}, at * 1000);
}

function buffered () {
	v.style.opacity = 1;
	doneOnce = true;

	v.play();

	obj.playing[0] = true;

	obj.aDom[0].parentElement.classList.add('active');

	showText(obj.textTiming[0], obj.tDom[0].parentElement);

	obj.aDom[0].querySelector('.progress-bar').style.transitionDuration = obj.duration[0] + 's';
	obj.aDom[0].querySelector('.progress-bar').style.width = '100%';

	stopVideo(obj.duration[0]);
}

function changeLap (i) {
    
    $('.versions li').removeClass('active');
    
	if (!obj.aDom[i].parentElement.classList.contains('active')) {
    	v.play();
		v.currentTime = obj.lapBegin[i];
	}

	for (var t = 0; t < obj.tDom.length; t++) {
		obj.tDom[t].parentElement.classList.remove('active');
	}

	clearTimeout(texter);
	clearTimeout(stopper);

	v.play();
	
	for (var j = 0; j < obj.aDom.length; j++) {
		obj.aDom[j].querySelector('.progress-bar').style = '';
		obj.aDom[j].parentElement.classList.remove('active');
		
		obj.playing[j] = false;
	}
	obj.playing[i] = true;
	
	obj.aDom[i].parentElement.classList.add('active');

	showText(obj.textTiming[i], obj.tDom[i].parentElement);

	obj.aDom[i].querySelector('.progress-bar').style.transitionDuration = obj.duration[i] + 's';
	setTimeout(function () {
		obj.aDom[i].querySelector('.progress-bar').style.width = '100%';
	},10);

	stopVideo(obj.duration[i]);
}

for (var i = 0; i < obj.aDom.length; i++) {
	a = obj.aDom[i];

	a.addEventListener('click', function (e) {
		e.preventDefault();

		if (!obj.playing[this.dataset.index]) changeLap(this.dataset.index);
	});
}

//

$(document).ready(function() {
	
	$('.options a').click(function(){
    	$('.versions li.active').removeClass('active');
    	$(this.hash).addClass('active');
    	return false;
	});
	
    var sections = $('section.startonscroll');

    if(sections.length > 0) {
        
        var checkSections = function(){
            
            var scrollTop = $(window).scrollTop();
            var scrollBottom = scrollTop + $(window).height();
            
            sections.each(function(){
                //var pos = $(this).offset().top + Math.round( $(this).height() / 3 ); 
                var pos = $(this).offset().top; 
                if( scrollBottom > pos ) {
                    events.push('scroll');
                	if(events.length == 2) startSlider();
                }
            });
            
        }
        
        checkSections();
        $(window).scroll(checkSections);        
    }    
});