$(document).ready(function(){
	
	$('a[data-inbound-gallery]').click(function(){
		var images = $(this).data('inbound-gallery');
		if(images.length > 0) {
			var datas = [];
			for(var i in images){
				var image = images[i];
				
				// Preload
				var img = new Image();
				img.src = '/sites/all/themes/shared/utils/proximage.php?f=' + image.image;
				
				var captions = '<strong>Model</strong>: ' + image.captions.model + ' - ';
					captions += '<strong>Creator</strong>: ' + image.captions.creator + ' - ';
					captions += '<strong>Architect</strong>: ' + image.captions.architect + ' - ';
					captions += '<strong>Photograph</strong>: ' + image.captions.photograph;
				
				datas.push({
					src : '/sites/all/themes/shared/utils/proximage.php?f=' + image.image_hd,
					opts : {
						caption : captions,
						thumb   : '/sites/all/themes/shared/utils/proximage.php?f=' + image.image
					}
				});
			}
			
			$.fancybox.open(datas, {
				loop : true,
				buttons : [
					'download',
					'thumbs',
					'close'
				  ]
			});
		}
	});
	
	if( $('.type-inbound').length > 0 ) {
    	
    	$('.inbound-template-selector').change(function(){
	    	var param = $(this).attr('name');
	    	var value = ($(this).val());
	    	
	    	var pathname = window.location.pathname;
	    	var queries	 = window.location.search;
	    	
	    	var queryObj = {};
	    	var raw = queries.replace('?', '').split('&');
	    	for(var o in raw) {
		    	var splits = raw[o].split('=');
		    	queryObj[splits[0]] = splits[1] !== undefined ? splits[1] : '';
	    	}
	    	
	    	queryObj[param] = value;
	    	
	    	var queryString = [];
	    	for(var p in queryObj) {
		    	queryString.push(p + '=' + queryObj[p]);
	    	}
	    	
	    	queryString = '?' + queryString.join('&');
	    	
	    	window.location.href = pathname + queryString;
	    	
    	});
    	
    	$('.choices input[type=checkbox]').change(function(){
	    	var values = [];
	    	if($('.choices input[type=checkbox]:checked').length <= 0) {
		    	$('.inbound-template-block').removeClass('last-column').show();
		    	$('.inbound-template-block').each(function(k){
			    	if((k+1)%3 == 0) $(this).addClass('last-column');
		    	});
		    	return false;
	    	}
	    	$('.inbound-template-block').removeClass('last-column').hide();
	    	
	    	var index = 0;
	    	$('.choices input[type=checkbox]:checked').each(function(){
		    	var value = $(this).val();
		    	
		    	var element = $('.inbound-template-block.' + value);
		    	index++;
		    	
		    	if(index > 0 && index%3 == 0) $(element).addClass('last-column');
		    	$(element).show();
		    	
		    	values.push(value);
		    });
    	});
    	
    	$('.type-inbound .checkbox-multiple.unique').change(function(){
        	$('.type-inbound .checkbox-multiple.unique').not(this).prop("checked", false);
    	});
    	
		$('*[data-alt-picture]').each(function(){
			var url = $(this).css('background-image').match(/\((.*?)\)/)[1].replace(/('|")/g,'');
			$(this).data('orig-picture', url);
			
			var loader = new Image();
			loader.src = url;
			
		});
		
		$('*[data-alt-picture]').hover(function(){			
			$(this).css('background-image', "url('" + $(this).data('alt-picture') + "')");			
		},function(){
			$(this).css('background-image', "url('" + $(this).data('orig-picture') + "')");
		});
		
		$('.inbound-list-block a').click(function(){
			
			var text = $(this).text();
			var link = $(this).attr('href');
			
			var options = {
				eventCategory: 'inbound', 
				eventAction: 'service', 
				eventLabel: text
			};
			
			var hasBeaconTransport = (typeof(navigator.sendBeacon) == 'function');
			
			if(hasBeaconTransport) {
				options.transport = 'beacon';
			} else {
				options.hitCallback = function(){
					window.location.href = link;
				};
			}
			
			try {
				ga('send', 'event', options);
			} catch(e) {}
			
			return hasBeaconTransport;
		});
	}
	$('.inbound-list-block').click(function(){
	   $(this).siblings('.modal_download-pdf').fadeIn(300); 
    });

	$('.close-modal').click(function(){
	   $('.modal_download-pdf').fadeOut(300);
    });

    $('.form-to-download').submit(function(){
    	window.open($(this).find('button').data('href'),'_blank');
	});
	
	$('.bloc_covid-close').click(function(){
		$('.bloc_covid').fadeOut(300);
	 });

});

if($("section").hasClass("page-inscription")==true){
	$("body").addClass("inbound");
}
if($("section").hasClass("page-connexion")==true){
	$("body").addClass("inbound");
}