var isMobile = function(){
	return $(window).width() <= 840;
};

var _isMobile = isMobile;

$(document).ready( function(){
	
	$('#nav-icon').click(function(){
		$("nav").toggleClass("open");
		$("body").toggleClass("open");
	});
	
	$('nav .close').click( function(){
		$(this).parents('nav').removeClass("open");
		$("body").removeClass("open");
	});	
	
	$('nav .choose-product a').click(function(){
		
		var product = $(this).data('product');
		//$('.filters select:first-child').val(product).trigger('change');
		activateChooser(product);

		$('.product-chooser.' + product).addClass('active');
		$('nav .close').get(0).click();
	});
	
	$('nav a.contact, .footer-block .contact').click(function(){
		
		$('body').addClass('noscroll');
		$('.contact').addClass('active');
		$('nav .close').get(0).click();
		
		if(window.location.pathname.indexOf('/contact') < 0) {
			history.pushState(null, null, window.location.pathname.replace(/\/$/, '') + '/contact' );
			try {
				ga('send', 'pageview', window.location.pathname);
			} catch(e) {}
		}
	});
	
	$('#contactForm input, #contactForm textarea, #contactForm select').focus(function(){
		$(this).removeClass('warning');
	});
	
	$('.button.open_selector_wood').click(function(){
		//$('nav .choose-product a[data-product=wood]').get(0).click();
		$('a.menu_models ').get(0).click();
	});
	
	$('.button.open_selector_gas').click(function(){
		//$('nav .choose-product a[data-product=gas]').get(0).click();
		$('a.menu_models ').get(0).click();
	});
	
	$('#contactForm').submit(function(evt){
		var error = false;
		
		$(this).find('.required').each(function(){
			if( $(this).val().length <= 0 ) {
				error = true;
				$(this).addClass('warning');
			}
		});
		
		if(error) {
			evt.preventDefault();
			return false;
		}
		
		return true;
	});
	
	$('.langue-selector > ul > li a').click(function(){
		if(_isMobile()) {
			
			$('.langue-selector').addClass('active');
			if($(this).parent().hasClass('children')) {
				
				$('.langue-selector > ul > li ul').removeClass('open-mobile');
				$(this).parent().find('ul').addClass('open-mobile');
			
			} else {
				
				if($(this).parent().hasClass('active'))
					return false;
				
				return true;
			}
			
			return false;
		}
	});
	
	$('.langue-selector .close a').click(function(){
		$('.langue-selector').removeClass('active');
		$('.langue-selector > ul > li ul').removeClass('open-mobile');
	});
	
	$('.children').hover(function() {
		if(!_isMobile()){
			var parent = $(this);
			var child = $(this).find('ul');
			child.css({left: parent.width()/2 - child.width()/2});
			child.stop(true, true).delay(200).fadeIn(300);
		}}, function() {
			if(!_isMobile()){
				$(this).find('ul').stop(true, true).delay(200).fadeOut(300);
			}
	});

	// if(_isMobile() &&  $('.langue-selector ul li.active.children').length > 0) {
	// 	$('.langue-selector').addClass('with-flag');
	// }

	var currentUrl = window.location.pathname;

	$($('.menu-container [href="'+currentUrl+'"], .menu-container [href="'+currentUrl+'/"]').get(0)).addClass('active');
	$($('.menu-container .level-3 [href="'+currentUrl+'"], .menu-container .level-3 [href="'+currentUrl+'/"]').get(0)).parents('.level-2').prev().addClass('parent_active');
});


$(document).ready( function(){ 
	// var langueButton = document.getElementById('language-opener-fullscreen');
	var langueButton = $('#language-opener-fullscreen');
	var body = $('body');
	var isOpen = false;
	var isChildOpen = false;
	var langueSelector = $('#language-selector');
	var langueSelectorUlParent = $('#langue-selector-ul-parent');
	var langueSelectorLiParents = $('.langue-selector-li-parent');
	var langueSelectorUlChildren = $('.langue-selector-ul-child');
	var langueSelectorClose = $('#langue-selector-close');

	langueButton.on('click', function() {
		if (!isOpen) {
			langueSelector.addClass("opened");
			toggleClassOfChildUl();
			isOpen = !isOpen;

		} 
	})

	// langueButton.addEventListener("click", openLanguage, false);

	// function openLanguage() {
	// 	console.log('openLanguage')
	// 	if (isOpen) {
	// 		langueSelector.removeClass("opened");
	// 		toggleClassOfChildUl();
	// 		isOpen = !isOpen;
	// 	} else {
	// 		langueSelector.addClass("opened");
	// 		toggleClassOfChildUl();
	// 		isOpen = !isOpen;
	// 	}
	// }

	for (var i = 0; i < langueSelectorLiParents.length; i++) {
		$(langueSelectorLiParents[i]).on('click', function(evt) {
			evt.preventDefault();
			if (evt.currentTarget.id != 'langue-selector-close') {
				if (isOpen) {

					var thisInClickLi = this; 
					if ($(thisInClickLi).find('ul').length == 0) {
						goToUrl(thisInClickLi);
					} 

					if ( $(thisInClickLi).children().length > 1 ) {
						toggleClassOfChildUl();
						var child = $(thisInClickLi).find("ul");
						child.addClass('active');
						var children = $(child).find('li');
						for (var j = 0; j < children.length; j++) {
							$(children[j]).on('click', function(e) {
								e.stopPropagation()

							})
						}
						isChildOpen = !isChildOpen;
					} 

					if (isOpen && isChildOpen && $(thisInClickLi).find('ul').hasClass("active")) {
						$(thisInClickLi).on('click', function() {
							goToUrl(thisInClickLi);
						})
					}
					

				} else {
					isOpen = !isOpen;
					langueSelector.addClass("opened");
				}
			}
		})
	}

	langueSelectorClose.on('click',function(){
		isOpen = !isOpen;
		body.removeClass("langue-open");
		langueSelector.removeClass("opened", 1000, "easeInBack");
		toggleClassOfChildUl();
	})

	function toggleClassOfChildUl() {
		for (var i = 0; i < langueSelectorUlChildren.length; i++) {
			$(langueSelectorUlChildren[i]).removeClass('active');
		}
	}

	function goToUrl(element) {
		var currentLi = $(element)[0];
		var currentA = $(currentLi).find("a:first")[0];
		location.href = $(currentA).attr('href');
		// $(location).attr('href', $(currentA).attr('href'));
	}
});



