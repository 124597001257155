$(document).ready( function(){
	
	$('form').each(function(){
		var action = $(this).attr('action');
		switch(true) {
			case action.indexOf('form=') > -1:
			case action.indexOf('submit.php') > -1:
				console.log(action);
				
				$(this).append('<input type="hidden" name="_token" value="' + Math.round(Math.random() * 9999999999999) + '" >')
				
				break;
		}
		
	});

	$('.open_models').click(function(){
	    $('.block-demo').css('transform', 'translateY(0)');
    });

	$('.close-gamme').click(function(){
		$('.block-demo').css('transform', 'translateY(-100vh)'); 
	 });
	
	$('div.contact .close').click(function(){
		$('.contact').removeClass('active');
		$('body').removeClass('noscroll');
		if( window.location.pathname.indexOf("/contact") > -1 ) {
			history.pushState(null, null, window.location.pathname.replace('/contact', ''));
		}
	}); 
	
	if( window.location.pathname.indexOf("/thanks") > -1 ) {
		$('.contact-thanks').fadeIn();
	}
	
	$('.contact-thanks .close').click(function(){
		$('.contact-thanks').fadeOut();
	});
	
	$('.controller').change(function(){
		
		var index = $(this).get(0).selectedIndex;
		var option = $(this).find('option').get(index);
		var open = $(option).data('open');
		
		if( undefined !== open ) {
			$('.contact .' + open).show();
			
			switch(open) {
				case 'documentation':
					$('.contact .' + open + ' select').material_select();
					break;
			}
			
		} else {
			$('.contact .extra').hide();
		}		
	});
	
	$('input#_position').remove();
});