$(document).ready(function () {

  if ($(".splash").length >= 1) {

    $('body').addClass('splash');
    
    var activateLanguage = function(lang){
	    
	    if(undefined === lang || lang.trim().length === 0) {
		    lang = 'en';
	    }
	    
	    $('.translable').hide();
        $('.translable.' + lang).show();
    };

    $('#language').change(function () {

      // center first block
      $('.selector-block:first-of-type').removeClass('animate-left');

      //clearing any ckecked checkbox
      $(':checkbox').prop('checked', false);

      var option = $(this).find(':selected');
      var countries = option.data('country');
	  
	  if( option.val().trim() === "" ) {
		  activateLanguage();
	  }
	  
      if (undefined !== countries) {
        //turn off button
        $('button').removeClass('not-empty');

        // move 1st block left
        $('.selector-block:first-of-type').addClass('animate-left');

        $('#location-panel').removeClass('invisible');
        $('#location-panel .label-block').addClass('hidden');
        
        activateLanguage($(this).val());

        countries = countries.split(',');
        for (var i in countries) {
          $('#' + countries[i]).parent().removeClass('hidden');
        }

      } else {
        $('#location-panel').addClass('invisible');

        // check if language is chosen or not
        if (option.val().trim() === "") {
          $('button').removeClass('not-empty');
        } else {
          $('button').addClass('not-empty');
        }
      }

    });

    $(':checkbox').click(function () {
      $(this).prop('checked', true);
      $(':checkbox').not(this).prop('checked', false);
      $('button').addClass('not-empty');
    });

	var saveAndSubmit = function(){
		var userPref = $('#lang-pref').serialize();
        Cookies.set('lang-pref', userPref);
        location.href = "/";
	};

    $('button').click(function () {
      if ($(this).hasClass('not-empty')) {
        saveAndSubmit();
      }
    });
	
	// Autosubmit
	  
	 $('#language').change(function () {
		 var option = $(this).find(':selected');
		 var countries = option.data('country');
		 if (undefined === countries && option.val().trim() !== "") {
			 saveAndSubmit();
		 }
	 });
	 
	 $('.splash .label-block input:checkbox').click(function () {
		 saveAndSubmit();
	 });

  }

});
