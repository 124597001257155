function parseURL(url) {
    var parser = document.createElement('a'),
        searchObject = {},
        queries, split, i;
    // Let the browser do the work
    parser.href = url;
    // Convert query string to object
    queries = parser.search.replace(/^\?/, '').split('&');
    for( i = 0; i < queries.length; i++ ) {
        split = queries[i].split('=');
        searchObject[split[0]] = split[1];
    }
    return {
        protocol: parser.protocol,
        host: parser.host,
        hostname: parser.hostname,
        port: parser.port,
        pathname: parser.pathname,
        search: parser.search,
        searchObject: searchObject,
        hash: parser.hash
    };
}

$(document).ready( function(){
	
	var filters = $('.blog-filters select');
	if(filters.length > 0) {
		filters.change( function(){
			
			var form 	= $('.blog-filters');
			var action  = form.attr('action');
			
			var uri		= parseURL(action).pathname + "/";
			
			form.find('select').each(function(){
				if( $(this).val().length > 0 ) {
					uri += $(this).val() + '/';
				}	
			});
						
			if(uri != form.data('current-url')) {
				window.location = uri;
			}
			
		});					
	}
	
	if( $('.no-search').length > 0 ) {
		var checker = setInterval(function(){
			if( $('.template-content-container .dropdown-content').length > 0 ) {
				clearInterval(checker);
				
				var hr = 0;
				$('.template-content-container .dropdown-content').each(function(){
					hr = Math.max(hr, $(this).height());
				});
				
				$('.template-content-container .no-search').height(hr);
			}
		}, 500);
	}
	
	$('.inbound-list form input, .inbound-list form textarea').focus(function(){
		$(this).removeClass('error-warning');
	});
	
});