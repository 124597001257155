var $slides = $('[data-slides]');
if( $slides.length > 0 ) {
  $slides.each(function(){
      
   var slide = $(this);   
   var images = slide.data('slides');
   
   var slideshow = function(images, it, slide){
       
		var img = new Image();
		img.onload = function(){
			slide.css('background-image', 'url("' + images[it] + '")')
			.show(0, function() {
				setTimeout(function(){
					if( it >= images.length-1 ) it = -1;
					slideshow(images, it+1, slide);
				}, 5000);
      		});
		};
		
		img.src = images[it]; 
   };
   
   slideshow(images, 0, slide);
  });
}



$(document).ready( function(){

	if( $('.type-inbound').length > 0 ) {
		$('body').addClass('inbound');
	}

	var fullvideos = $('.video-bg video');
	try {
		fullvideos.each(function(){
			
			var $video = $(this);
			var $clone = $video.clone();
			$video.after($clone);
		
			var video = $video[0];
			var clone = $clone[0];
		
			clone.muted = true;
			//video.muted = true;
		
			$(clone).css('z-index', 0);
			$(video).css('z-index', 5);
			
			clone.pause();
			clone.currentTime = 0;
		
			var startOffset = 0.3;
			var loopOffset = 0.1;
			
			var isFirefox = typeof InstallTrigger !== 'undefined';
			
			if(isFirefox){
				startOffset = 0.1;	
			}
		
			video.ontimeupdate = function() {
				if (video.currentTime >= video.duration - startOffset) {
					clone.play();
				}
				
				if (video.currentTime >= video.duration - loopOffset) {
					$(video).css('z-index', 0);
					$(clone).css('z-index', 5);
					
					video.pause();
					video.currentTime = 0;
				}
			}
		
			clone.ontimeupdate = function() {
				if (clone.currentTime >= clone.duration - startOffset) {
					video.play();
				}
				
				if (clone.currentTime >= clone.duration - loopOffset) {
					$(clone).css('z-index', 0);
					$(video).css('z-index', 5);
					
					clone.pause();
					clone.currentTime = 0;
				}
			}
			
			/*this.addEventListener("timeupdate", function () {
				if(this.currentTime >= 3.9) {
					this.currentTime = 0;
				}
			});*/
		});

	} catch(e) {
		//
	}
	
	var sliders = $('.slider_features');

	sliders.each(function () {
		var timing = 8000;
		var slider = $(this);

		var slides = slider.find('li');
		var paginations = $(slider.next('ul.pagination')).find('a');
		var bar = slider.siblings('.progressBar');

		function changeSlide (slides, iteration, cancel) {
			cancel = cancel || false;
			if (cancel) bar.stop();
			bar.css('width', 0);

			slides.hide();
			var current = $(slides[iteration]);
			// var current = $(slides.get(iteration));
			current.fadeIn();

			$(current.parents('ul').next('.pagination').find('li').get(iteration)).addClass('active');

			current.find('li').append(bar);

			bar.animate(
				{ width: '100%' },
				timing,
				'linear',
				function () {
					$(current.parents('ul').next('.pagination').find('li').get(iteration)).removeClass('active');

					if (iteration >= slides.length-1) iteration = -1;
					changeSlide(slides, iteration+1);
				}
			);
		};

		changeSlide(slides, 0);

		paginations.click(function () {
			var i = paginations.index(this);

			changeSlide(slides, i);

			return false;
		})
	});
	
	$('.click-godown').click( function(){
		$('.block-slider .godown').trigger('click');
	});
	
	if( location.hash.indexOf('showroom') > -1 ) {
		var showroom = $( "div:contains('showroom')" );
		if(showroom) {
			var scrolling = showroom.last().offset().top - 400;
			$('html, body').scrollTop(scrolling);
			setTimeout(function(){
				$('html, body').scrollTop(scrolling);
			}, 2000);
		}
	}



});

