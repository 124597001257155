var activateChooser = function(product, index){
	
	if(undefined === index) index = 0;
	if(product.indexOf('page') <= -1) $('body').addClass('noscroll');
	
	$('.product-chooser.' + product + ' select.checkall').each(function(){
		var values =  [];
		$(this).find('option').each(function(){
			$(this).attr('selected', true);
			values.push($(this).val());
		});
		$(this).val(values);
	});
	
	$('.material_special_select select').material_select();
	$('.product-chooser.' + product + ' select.checkall').trigger('change');
};

$(document).ready(function(){

	var selects = $('.material_special_select select');
	selects.material_select();
	
	/*$("select.filter").change(function(){
		
		var product = $(this).parents('.product-chooser').data('product');
		var products = [];
		
		$( "." + product + " " + "select.filter").each(function() {
			var arr = $(this).val();
			if( null !== arr && arr.length > 0 ) products.push(arr);
		});
		
		if( products.length === 0 ) {
			$("." + product + " " +'.products .item').show();
			return false;
		}
				
		var filterArrayBy = function(arr, items) {
			if(arr.length <= 0) return items;
			
			var filters = arr.shift();
			var filter = '.' + filters.join(', .');
			
			return filterArrayBy(arr, $(items).filter(filter) );
		};
				
		var items = filterArrayBy(products, $("." + product + " " +'.products .item'));
		$("." + product + " " +'.products .item').hide();
		$(items).show();
						
		if(items.length <= 0) {
			$('.product-chooser.' + product + ' .nothing').show();
		} else {
			$('.product-chooser.' + product + ' .nothing').hide();
		}		
	});*/
	
	$('.holder-selector input[type=checkbox]').change(function(){
		var products = [];
		
		$('.holder-selector').each( function(){
			
			var arr = [];
			
			$(this).find('input[type=checkbox]').each( function(){
				if($(this).is(':checked')) {
					arr.push($(this).val());
				}
			});
			
			if(arr.length  > 0) products.push(arr);	
		});
		
		if( products.length === 0 ) {
			$('.product.products .item').show();
			$('.results .total').html( $('.product.products .item').length );
			return false;
		}
		
		var filterArrayBy = function(arr, items) {
			if(arr.length <= 0) return items;
			
			var filters = arr.shift();
			var filter = '.' + filters.join(', .');
			
			return filterArrayBy(arr, $(items).filter(filter) );
		};
		
		var items = filterArrayBy(products, $('.product.products .item'));
		$('.product.products .item').hide();
		$(items).show();
		
		$('.results .total').html( items.length );
						
		if(items.length <= 0) {
			$('.product.products .nothing').show();
		} else {
			$('.product.products .nothing').hide();
		}
		
	});
	
	
	$('.product-chooser .close, .close-scrolling').click( function(){
		
		$('.product-chooser').removeClass('active');
		$('.close-scrolling').addClass('hide');	
		
		$('body').removeClass('noscroll');
	});
	
	$('.product-chooser').scroll( function(){
		if( $(this).hasClass('active') ) {
			if($(this).scrollTop() > $('.product-chooser .close').height()) {
				$('.close-scrolling').removeClass('hide');
			} else {
				$('.close-scrolling').addClass('hide');
			}
		}
	});
	
	if($('.in-product-page').length > 0 ) activateChooser('in-product-page');
});       