$(document).ready(function() {
    $('.pos .selector select').material_select();
    $('.blog-main .selector select').material_select();
});

$('.postal-js').blur(function(){
    tmpval = $(this).val();
    if(tmpval === '') {
        $('.do-search').addClass('empty');
        $('.do-search').removeClass('not-empty');
    } else {
        $('.do-search').addClass('not-empty');
        $('.do-search').removeClass('empty');
    }
});

$(document).ready( function(){


     $('.pos-product-selector a.close').click( function() {

        $('.pos-product-selector').removeClass('active');
        return false;
    });
    
});
//UNCHECK ALL




$('.uncheck').click(function() {

  var _this = this;
  $('.checkbox-holder').find('input[name="checkboxing"]').each(function() {
   
    if ($(_this).is(':checked')) {
      $(this).prop('checked', true);
    } else {
      $(this).prop('checked', false);
    }
  });
});

$(window).scroll(function(){
	
	if($('.in-scroll').length > 0) {
	
		if($('.back-button:not(.in-scroll)').offset().top <= $(window).scrollTop()) {
			$('.in-scroll').addClass('visible');	
		} else {
			$('.in-scroll').removeClass('visible');
		}		
	}	
});

if(swiper) {

  var swiper = new Swiper(".mySwiperDealers", {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });
  
  var swiper = new Swiper(".mySwiperRealisation", {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

}